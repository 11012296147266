import React from 'react';
import PropTypes from 'prop-types';
import TooltipWrapper from 'components/TooltipWrapper/TooltipWrapper';
import { Chip } from 'cprime-foundational-components';

const StatusChip = ({ color, backgroundColor, icon, label, tooltipText, variant, sx }) => {
  const chipComponent = (
    <Chip
      variant={variant}
      label={label}
      icon={icon}
      color={color}
      backgroundColor={backgroundColor}
      sx={{
        ...sx,
        '&.MuiChip-outlined': {
          borderColor: '#bdbdbd',
        },
        '&, *': {
          cursor: 'pointer',
        },
      }}
    />
  );

  if (tooltipText) {
    return (
      <TooltipWrapper tooltipText={tooltipText}>
        <div style={{ maxWidth: '100%', display: 'inline-block' }}>{chipComponent}</div>
      </TooltipWrapper>
    );
  }

  return chipComponent;
};

StatusChip.defaultProps = {
  label: null,
  icon: null,
  tooltipText: null,
  variant: 'filled',
};

StatusChip.propTypes = {
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  icon: PropTypes.node,
  label: PropTypes.string,
  tooltipText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  variant: PropTypes.oneOf(['filled', 'outlined']),
  sx: PropTypes.object,
};

export default StatusChip;
