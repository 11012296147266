import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Stack, Typography } from '@mui/material';
import {
  Cancel as CancelIcon,
  CheckCircle as CheckCircleIcon,
  Close as CloseIcon,
  Info as InfoIcon,
  Warning as WarningIcon,
} from '@mui/icons-material';
import * as S from './Notification.styles.js';
import { Button } from 'cprime-foundational-components';

const ICON_MAP = {
  info: <InfoIcon fontSize="small" />,
  success: <CheckCircleIcon fontSize="small" />,
  warning: <WarningIcon fontSize="small" />,
  error: <CancelIcon fontSize="small" />,
};

const Notification = forwardRef(
  (
    { inline, title, subtitle, actionTitle, timestamp, severity, disableIcon, fullWidth, handleAction, handleClose },
    ref,
  ) => (
    <S.Alert
      ref={ref}
      severity={severity}
      action={
        handleClose && (
          <IconButton size="small" onClick={handleClose} disableRipple sx={{ padding: '0.25rem' }}>
            <CloseIcon fontSize="small" />
          </IconButton>
        )
      }
      icon={!disableIcon && ICON_MAP[severity]}
      inline={+inline}
      fullwidth={+fullWidth}
      timestamp={timestamp}
      subtitle={subtitle}
      actiontitle={actionTitle}
      sx={{ borderRadius: fullWidth ? 0 : 1, width: fullWidth ? '100%' : 'auto' }}
    >
      <Stack
        direction={inline ? 'row' : 'column'}
        spacing={2}
        width="100%"
        justifyContent={inline ? 'space-between' : 'start'}
      >
        <Stack spacing={1}>
          {timestamp && (
            <Typography component="label" variant="label2" color="text.secondary">
              {timestamp}
            </Typography>
          )}
          <Stack spacing={timestamp || inline ? 0.5 : 1}>
            <Typography component="h5" variant="body1">
              {title}
            </Typography>
            {subtitle && (
              <Typography component="p" variant="caption2">
                {subtitle}
              </Typography>
            )}
          </Stack>
        </Stack>
        {handleAction && actionTitle && (
          <Button
            variant="ghost"
            onClick={handleAction}
            sx={{
              padding: '0rem',
              width: 'min-content',
              minWidth: 0,
              '&:hover': { backgroundColor: 'transparent' },
            }}
          >
            <Typography component="label" variant="body2" color="info.main">
              {actionTitle}
            </Typography>
          </Button>
        )}
      </Stack>
    </S.Alert>
  ),
);

Notification.displayName = 'Notification';

Notification.defaultProps = {
  inline: true,
  fullWidth: false,
  disableIcon: false,
  timestamp: null,
  handleAction: null,
  handleClose: null,
};

Notification.propTypes = {
  inline: PropTypes.bool,
  fullWidth: PropTypes.bool,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  actionTitle: PropTypes.string,
  timestamp: PropTypes.string,
  severity: PropTypes.oneOf(['info', 'success', 'warning', 'error']).isRequired,
  disableIcon: PropTypes.bool,
  handleAction: PropTypes.func,
  handleClose: PropTypes.func,
};

export default Notification;
